import { render, staticRenderFns } from "./tradein-column-customer.vue?vue&type=template&id=d6f894de&scoped=true&"
import script from "./tradein-column-customer.vue?vue&type=script&lang=js&"
export * from "./tradein-column-customer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6f894de",
  null
  
)

export default component.exports