<template>
  <div class="flex flex-col">
    <div class="flex flex-row">
      <div v-if="nil(item.customer_email) != ''" @click="mail" style="min-width: 24px;" class="text-gray-300 hover:text-blue-600 cursor-pointer"><i class="fa fa-envelope"></i></div>
      <div v-else style="min-width: 24px;"  class="text-gray-300"> </div>
      <div class="ml-2"> {{item.customer_last}} {{item.customer_first}}</div>
    </div>
    <div class="flex flex-row">
      <div v-if="nil(item.customer_telephone) != '' || nil(item.customer_mobile)" @click="call" style="min-width: 24px;" class="text-gray-300 hover:text-blue-600 cursor-pointer"><i class="fa fa-phone"></i></div>
      <div v-else style="min-width: 24px;"  class="text-gray-300"> </div>
      <div class="ml-2">{{item.customer_postcode}} {{item.customer_city}}</div>
    </div>
  </div>
</template>

<script>
import {nil} from '@/common';

export default {
  name: "tradein-column-customer",
  props: ['column', 'item'],
  methods: {
    call() {
      console.log('call');
    },
    mail() {
      console.log('mail');
    },
    nil: nil
  }
}
</script>

<style scoped>

</style>